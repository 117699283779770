import React from 'react'
import styled from "styled-components"

const Button = () => {
  return (
    <>
    <Wrapper>
    <div>
      <button className='btn'>Buy on amazon</button>
    </div>
    </Wrapper>
    </>
  )
}
const Wrapper = styled.button`
height:40px;
`

export default Button
