import { FiInfo } from 'react-icons/fi';
import { Button, Counter, LikeButton, Video } from "../../../../src/components/Complete";
import { Link } from 'gatsby';
import Seo from "../../../../src/components/SEO";
import { FaRegFontAwesomeLogoFull } from 'react-icons/fa';
import videoMP4_003 from "../../../../src/posts/8-post-number-eight/videos/chosen-few.mp4";
import videoMP4_004 from "../../../../src/posts/8-post-number-eight/videos/fruits.mp4";
import * as React from 'react';
export default {
  FiInfo,
  Button,
  Counter,
  LikeButton,
  Video,
  Link,
  Seo,
  FaRegFontAwesomeLogoFull,
  videoMP4_003,
  videoMP4_004,
  React
};