import { FiInfo } from 'react-icons/fi';
import { Button, Counter, LikeButton, Video } from "../../../../src/components/Complete";
import { Link } from 'gatsby';
import Seo from "../../../../src/components/SEO";
import * as React from 'react';
export default {
  FiInfo,
  Button,
  Counter,
  LikeButton,
  Video,
  Link,
  Seo,
  React
};