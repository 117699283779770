import React from "react"
import styled from "styled-components"


const Video = ({ videoSrcUrl }) => {
  return (
    <>
      <Wrapper>
        <video controls autoPlay muted loop>
          <source src={videoSrcUrl} type="video/mp4" />
        </video>
      </Wrapper>
    </>
  )
}
const Wrapper = styled.section`
 width: 80vw;
  max-width: 800px;
  height: 30vh;
  position: relative;
  margin-bottom: 2rem;
  margin:0 auto;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  }
`
export default Video