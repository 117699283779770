import { FiInfo } from 'react-icons/fi';
import { Button, Counter, LikeButton, Video } from "../../../../src/components/Complete";
import { Link } from 'gatsby';
import Seo from "../../../../src/components/SEO";
import { FaRegFontAwesomeLogoFull } from 'react-icons/fa';
import videoMP4_005 from "../../../../src/posts/9-post-number-nine/videos/spirit.mp4";
import videoMP4_006 from "../../../../src/posts/9-post-number-nine/videos/scripture.mp4";
import * as React from 'react';
export default {
  FiInfo,
  Button,
  Counter,
  LikeButton,
  Video,
  Link,
  Seo,
  FaRegFontAwesomeLogoFull,
  videoMP4_005,
  videoMP4_006,
  React
};